import AYM_00_CheckB from './AYM_00_CheckB.svg';
import AYM_01_Premium from './AYM_01_Premium.svg';
import AYM_02_Phone from './AYM_02_Phone.svg';
import AYM_03_Pincel from './AYM_03_Pincel.svg';
import AYM_04_Maletin from './AYM_04_Maletin.svg';
import AYM_05_Maps from './AYM_05_Maps.svg';
import AYM_06_Formulario from './AYM_06_Formulario.svg';
import AYM_07_Whatsapp from './AYM_07_Whatsapp.svg';
import AYM_08_Servicios from './AYM_08_Servicios.svg';
import AYM_09_Estadistica from './AYM_09_Estadistica.svg';
import AYM_10_Mail from './AYM_10_Mail.svg';
import AYM_11_BaseD from './AYM_11_BaseD.svg';
import AYM_12_Mundo from './AYM_12_Mundo.svg';
import AYM_13_Nave from './AYM_13_Nave.svg';
import AYM_14_React from './AYM_14_React.svg';
import AYM_15_Rayo from './AYM_15_Rayo.svg';
import AYM_16_Escudo from './AYM_16_Escudo.svg';
import AYM_17_Copias from './AYM_17_Copias.svg';
import AYM_18_Ssl from './AYM_18_Ssl.svg';

const IconAYM = [
    AYM_00_CheckB,
    AYM_01_Premium,
    AYM_02_Phone,
    AYM_03_Pincel,
    AYM_04_Maletin,
    AYM_05_Maps,
    AYM_06_Formulario,
    AYM_07_Whatsapp,
    AYM_08_Servicios,
    AYM_09_Estadistica,
    AYM_10_Mail,
    AYM_11_BaseD,
    AYM_12_Mundo,
    AYM_13_Nave,
    AYM_14_React,
    AYM_15_Rayo,
    AYM_16_Escudo,
    AYM_17_Copias,
    AYM_18_Ssl,



];

export default IconAYM;