import React from 'react';
import logo from '../assets/media/LOGOAYM180.webp';

const Footer = () => {
    return (
        <footer className="footer-container">
            <div className="footer-content">
                <div className="footer-logo">
                    <img src={logo} alt="Logo" className="footer-logo-img" />
                </div>


                <div className="footer-column">
                    <h3 className="footer-heading">A&M Páginas Web</h3>
                    <ul className="footer-links">
                        <li><a href="#nuestrosPlanes">Nuestros Planes</a></li>
                        <li><a href="#Formulario">Formulario de consulta</a></li>
                        <li><a href="#TrabajosRecientes">Trabajos Recientes</a></li>
                        <li><a href="#TrabajosRecientes">Lima - Perú</a></li>
                    </ul>


                </div>


                <div className="footer-column">
                    <h3 className="footer-heading">CONTÁCTACNOS</h3>
                    <span className='footer-span'>E-mail:</span>
                    <h4 className='footer-h4'>info@aympaginasweb.com</h4>
                    <span className='footer-span'>Celular:</span>
                    <h4 className='footer-h4'>+51 958071918</h4>


                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; {new Date().getFullYear()} A&M Paginas Web. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
