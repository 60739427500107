import React, { useState, useEffect } from 'react';
import Logo from '../../src/assets/media/LOGOAYM180.webp';
import IconAYM from '../icons/IconsAYM';
import ImgAYM from '../img/imgAYM';
import axios from 'axios';




import iconHome from '../iconHome/iconHome';


function Home() {

    const [rightClick, setRightClick] = useState(false);
    const handleContextMenu = (event) => {
        event.preventDefault();
        setRightClick(true);
    };


    const url = 'https://wa.me/+51958071918';

    const handleComprarClick = () => {
        window.open(url, '_blank');
    };






    const [nombre, setNombre] = useState('');
    const [email, setEmail] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [enviado, setEnviado] = useState(false);
    const [telefono, setTelefono] = useState('');
    const [telefonoError, setTelefonoError] = useState('');
    const [caracteres, setCaracteres] = useState(0);
    const [caracteresMax] = useState(500);



    const handleSubmit = async (e) => {
        e.preventDefault();
        if (telefono.length !== 9 || !/^9/.test(telefono)) {
            setTelefonoError(<span style={{ color: 'red' }}>Ingrese un número válido</span>);
            return;

        }


        try {
            await axios.post(
                'https://api.sendinblue.com/v3/smtp/email',
                {
                    sender: { name: nombre, email: email },
                    to: [{ email: 'gamz.miguel@gmail.com' }],
                    subject: 'FORMULARIO DE CONTACTO - AYMCONTRATISTA.COM',
                    htmlContent: `<html><head></head><body><p>NOMBRE: ${nombre}<br>CELULAR: ${telefono}<br>CORREO: ${email}<br><br>${mensaje}</p></body></html>`,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'api-key': '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',
                    },
                }
            );
        } catch (error) {
            console.log(error);
        } finally {
            setEnviado(true);
            // después de 5 segundos, se reinicia el formulario
            setTimeout(() => {
                setEnviado(false);
                setNombre('');
                setEmail('');
                setTelefono('');
                setMensaje('');
                setTelefonoError('');
            }, 2666);
        }
    };







    useEffect(() => {
        let currentIndex = 0;
        const slides = document.querySelectorAll('.slide');
        const nextBtn = document.querySelector('.nextBtn');
        const prevBtn = document.querySelector('.prevBtn');
        slides[currentIndex].classList.add('active');

        const nextSlide = () => {
            slides[currentIndex].classList.remove('active');
            currentIndex = (currentIndex + 1) % slides.length;
            slides[currentIndex].classList.add('active');
        };

        const prevSlide = () => {
            slides[currentIndex].classList.remove('active');
            currentIndex = (currentIndex + slides.length - 1) % slides.length;
            slides[currentIndex].classList.add('active');
        };

        nextBtn.addEventListener('click', nextSlide);
        prevBtn.addEventListener('click', prevSlide);

        const interval = setInterval(nextSlide, 3666);

        function limitSize() {
            if (window.innerWidth < 300) {
                document.body.style.width = '300px';
            } else {
                document.body.style.width = '100%';
            }
        }

        window.addEventListener('resize', limitSize);
        limitSize();

        return () => {
            nextBtn.removeEventListener('click', nextSlide);
            prevBtn.removeEventListener('click', prevSlide);
            clearInterval(interval);
            window.removeEventListener('resize', limitSize);
        };
    }, []);

















    return (
        <section className="home-container">


            <section className='Goldman6g'>





                <div className='Goldman__contenedor'>

                    <div className='presentacion__goldman6g'>

                        <div className='banner__AYM'>

                            <div className='banner__AYM__SPAN'>
                                <span className='izquierda__aym'> <img className='info-icon' src={IconAYM[10]} onContextMenu={handleContextMenu} alt='icon' />info@aympaginasweb.com</span>
                                <span className='derecha__aym'><img className='info-icon' src={IconAYM[7]} onContextMenu={handleContextMenu} alt='icon' />+51 958071918</span>
                            </div>
                        </div>


                        <div className='caratula__AYM'>



                            <img className='Logo__principal' src={Logo} onContextMenu={handleContextMenu} alt='Logo principal Goldman6g' title="Logo Goldman6g" />
                            {rightClick && null}
                            <h1 className='titulo__h1__aym'>Creación de Páginas Web Profesionales en Perú</h1>
                            <p className='subtitulo__p__aym'>Deseas tener una página web, tienda online y campañas que te ayuden a incrementar tus ventas?</p>

                            <div className='comenzar-button'>
                                <button onClick={handleComprarClick}>Comenzar</button>
                            </div>
                        </div>

                    </div>



                    <div className="presentacion__general">
                        <div className="div__presentacion__general">
                            <img className='Logo__presentacion__general' src={ImgAYM[0]} onContextMenu={handleContextMenu} alt='Logo principal Goldman6g' title="Logo Goldman6g" />
                            {rightClick && null}
                            <h2 className="h2_presentacion__general">Desarrollo Web</h2>
                            <h3 className="h3_presentacion__general">Los sitios web y las plataformas de comercio electrónico, con diseño adaptativo incorporado, exhibirán una excelente presentación en cualquier dispositivo.</h3>
                        </div>
                        <div className="div__presentacion__general">
                            <img className='Logo__presentacion__general' src={ImgAYM[1]} onContextMenu={handleContextMenu} alt='Logo principal Goldman6g' title="Logo Goldman6g" />
                            {rightClick && null}
                            <h2 className="h2_presentacion__general">Motion Graphics</h2>
                            <h3 className="h3_presentacion__general">Presenta de forma dinámica y atractiva el funcionamiento y los beneficios de tu marca, producto o servicio mediante animaciones y otros elementos audiovisuales.</h3>
                        </div>
                        <div className="div__presentacion__general">
                            <img className='Logo__presentacion__general' src={ImgAYM[2]} onContextMenu={handleContextMenu} alt='Logo principal Goldman6g' title="Logo Goldman6g" />
                            {rightClick && null}
                            <h2 className="h2_presentacion__general">SEO y SEM</h2>
                            <h3 className="h3_presentacion__general">Logra posicionarte en los primeros puestos de los buscadores y sé la opción principal para los clientes potenciales que te buscan.</h3>
                        </div>
                    </div>






















                    <div className='productos__goldman6g'>
                        <h2 id="nuestrosPlanes" className='formularioh2'>Nuestros Planes</h2>
                        <div className='grupo__planes__aym'>
                            <div class="planes__aym">
                                <h3>WEB EMPRENDEDOR</h3>
                                <div className='contenedor__precio__aym'>
                                    <span className='simbolo__precio__aym'>S/</span>
                                    <span className='cantidad__precio__aym'>499</span>
                                </div>

                                <ul class="lista__aym">
                                    <li> <img className='info-icon' src={IconAYM[0]} alt='icon' /> Hasta 8 secciones</li>
                                    <li><img className='info-icon' src={IconAYM[1]} alt='icon' /> Diseño Personalizado</li>
                                    <li><img className='info-icon' src={IconAYM[2]} alt='icon' /> Diseño Multiplataforma</li>
                                    <li><img className='info-icon' src={IconAYM[3]} alt='icon' /> Diseño de Logotipo</li>
                                    <li><img className='info-icon' src={IconAYM[4]} alt='icon' /> Gestión de Contenido</li>
                                    <li><img className='info-icon' src={IconAYM[5]} alt='icon' /> Incorporación de google maps</li>
                                    <li><img className='info-icon' src={IconAYM[6]} alt='icon' /> Formulario de Comunicación Directa</li>
                                    <li><img className='info-icon' src={IconAYM[7]} alt='icon' /> Integracion WhatsApp</li>
                                    <li><img className='info-icon' src={IconAYM[8]} alt='icon' /> Soporte técnico 24/7</li>
                                    <li><img className='info-icon' src={IconAYM[9]} alt='icon' /> 200 GB de Tráfico / mes</li>
                                    <li><img className='info-icon' src={IconAYM[10]} alt='icon' /> 10 Cuentas de E-mail Profesionales</li>
                                    <li><img className='info-icon' src={IconAYM[11]} alt='icon' /> 1 Base de datos</li>
                                    <li><img className='info-icon' src={IconAYM[12]} alt='icon' /> hosting y dominio .com</li>
                                    <li><img className='info-icon' src={IconAYM[13]} alt='icon' /> Interfaz rápida, moderna y dinámica</li>
                                    <li><img className='info-icon' src={IconAYM[14]} alt='icon' /> Uso de React y Virtual DOM</li>
                                    <li><img className='info-icon' src={IconAYM[15]} alt='icon' /> LiteSpeed + LSCache</li>
                                    <li><img className='info-icon' src={IconAYM[16]} alt='icon' /> Seguridad Imunify360</li>
                                    <li><img className='info-icon' src={IconAYM[17]} alt='icon' /> Copias de seguridad</li>
                                    <li><img className='info-icon' src={IconAYM[18]} alt='icon' /> SSL gratis (https://)</li>
                                </ul>
                                <div className='contenedor__boton__aym'>

                                    <button className="boton__comprar__aym" onClick={handleComprarClick}>
                                        Comprar
                                    </button>
                                </div>

                            </div>






                            <div class="planes__aym">
                                <h3>TIENDA VIRTUAL</h3>
                                <div className='contenedor__precio__aym'>
                                    <span className='simbolo__precio__aym'>S/</span>
                                    <span className='cantidad__precio__aym'>799</span>
                                </div>

                                <ul class="lista__aym">
                                    <li><img className='info-icon' src={IconAYM[0]} alt='icon' /> Hasta 10 secciones</li>
                                    <li><img className='info-icon' src={IconAYM[1]} alt='icon' /> Diseño Personalizado</li>
                                    <li><img className='info-icon' src={IconAYM[2]} alt='icon' /> Diseño Multiplataforma</li>
                                    <li><img className='info-icon' src={IconAYM[3]} alt='icon' /> Diseño de Logotipo</li>
                                    <li><img className='info-icon' src={IconAYM[4]} alt='icon' /> Gestión de Contenido</li>
                                    <li><img className='info-icon' src={IconAYM[5]} alt='icon' /> Incorporación de google maps</li>
                                    <li><img className='info-icon' src={IconAYM[6]} alt='icon' /> Formulario de Comunicación Directa</li>
                                    <li><img className='info-icon' src={IconAYM[7]} alt='icon' /> Integracion WhatsApp</li>
                                    <li><img className='info-icon' src={IconAYM[8]} alt='icon' /> Soporte técnico 24/7</li>
                                    <li><img className='info-icon' src={IconAYM[9]} alt='icon' /> 300 GB de Tráfico / mes</li>
                                    <li><img className='info-icon' src={IconAYM[10]} alt='icon' /> 20 Cuentas de E-mail Profesionales</li>
                                    <li><img className='info-icon' src={IconAYM[11]} alt='icon' /> 5 Base de datos</li>
                                    <li><img className='info-icon' src={IconAYM[12]} alt='icon' /> hosting y dominio .com</li>
                                    <li><img className='info-icon' src={IconAYM[13]} alt='icon' /> Interfaz rápida, moderna y dinámica</li>
                                    <li><img className='info-icon' src={IconAYM[14]} alt='icon' /> Uso de React y Virtual DOM</li>
                                    <li><img className='info-icon' src={IconAYM[15]} alt='icon' /> LiteSpeed + LSCache</li>
                                    <li><img className='info-icon' src={IconAYM[16]} alt='icon' /> Seguridad Imunify360</li>
                                    <li><img className='info-icon' src={IconAYM[17]} alt='icon' /> Copias de seguridad</li>
                                    <li><img className='info-icon' src={IconAYM[18]} alt='icon' /> SSL gratis (https://)</li>
                                </ul>
                                <div className='contenedor__boton__aym'>
                                    <button className="boton__comprar__aym" onClick={handleComprarClick}>
                                        Comprar
                                    </button>
                                </div>
                            </div>





                            <div class="planes__aym">
                                <h3>WEB EMPRESARIAL</h3>
                                <div className='contenedor__precio__aym'>
                                    <span className='simbolo__precio__aym'>S/</span>
                                    <span className='cantidad__precio__aym'>1,499</span>
                                </div>


                                <ul class="lista__aym">
                                    <li><img className='info-icon' src={IconAYM[0]} alt='icon' /> Hasta 15 secciones</li>
                                    <li><img className='info-icon' src={IconAYM[1]} alt='icon' /> Diseño Personalizado</li>
                                    <li><img className='info-icon' src={IconAYM[2]} alt='icon' /> Diseño Multiplataforma</li>
                                    <li><img className='info-icon' src={IconAYM[3]} alt='icon' /> Diseño de Logotipo</li>
                                    <li><img className='info-icon' src={IconAYM[4]} alt='icon' /> Gestión de Contenido</li>
                                    <li><img className='info-icon' src={IconAYM[5]} alt='icon' /> Incorporación de google maps</li>
                                    <li><img className='info-icon' src={IconAYM[6]} alt='icon' /> Formulario de Comunicación Directa</li>
                                    <li><img className='info-icon' src={IconAYM[7]} alt='icon' /> Integracion WhatsApp</li>
                                    <li><img className='info-icon' src={IconAYM[8]} alt='icon' /> Soporte técnico 24/7</li>
                                    <li><img className='info-icon' src={IconAYM[9]} alt='icon' /> 500 GB de Tráfico / mes</li>
                                    <li><img className='info-icon' src={IconAYM[10]} alt='icon' /> 50 Cuentas de E-mail Profesionales</li>
                                    <li><img className='info-icon' src={IconAYM[11]} alt='icon' /> 10 Base de datos</li>
                                    <li><img className='info-icon' src={IconAYM[12]} alt='icon' /> hosting y dominio .com</li>
                                    <li><img className='info-icon' src={IconAYM[13]} alt='icon' /> Interfaz rápida, moderna y dinámica</li>
                                    <li><img className='info-icon' src={IconAYM[14]} alt='icon' /> Uso de React y Virtual DOM</li>
                                    <li><img className='info-icon' src={IconAYM[15]} alt='icon' /> LiteSpeed + LSCache</li>
                                    <li><img className='info-icon' src={IconAYM[16]} alt='icon' /> Seguridad Imunify360</li>
                                    <li><img className='info-icon' src={IconAYM[17]} alt='icon' /> Copias de seguridad</li>
                                    <li><img className='info-icon' src={IconAYM[18]} alt='icon' /> SSL gratis (https://)</li>
                                </ul>
                                <div className='contenedor__boton__aym'>
                                    <button className="boton__comprar__aym" onClick={handleComprarClick}>
                                        Comprar
                                    </button>
                                </div>
                            </div>


                        </div>



                    </div>





                    <div className='AYM__CONTRATISTAS__SECTION__CONTACTO'>

                        <section className='AYM__CONTRATISTAS__SECTION__CONTACTO__CONTENEDOR'>

                            <div className='AYM__CONTRATISTAS__DIV__CONTACTO__CONTENEDOR'>

                                {!enviado ? (
                                    <form className='form__principal' onSubmit={handleSubmit}>
                                        <h2 id="Formulario" className='formularioh2'>Formulario de consulta </h2>

                                        <label htmlFor='full-name' className='form-label'>Nombre completo:<span style={{ color: 'red' }}>*</span></label>
                                        <input type='text' id='full-name' placeholder='NOMBRES Y APELLIDOS' value={nombre} onChange={(e) => setNombre(e.target.value)} maxLength={40} required />

                                        <label htmlFor='email' className='form-label'>Email:<span style={{ color: 'red' }}>*</span></label>
                                        <input type='email' id='email' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} maxLength={40} required />



                                        <label htmlFor='phone' className='form-label'>Celular:<span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            type='phone' id='phone' placeholder='Número de celular' value={telefono} onChange={(event) => {
                                                setTelefono(event.target.value);
                                            }}
                                            maxLength={9}
                                            required
                                            className={`telefono-input ${telefonoError ? 'error' : ''}`}
                                        />
                                        {telefonoError && <p className='telefono-error'>{telefonoError}</p>}


                                        <label htmlFor="mensaje" className="form-label">Mensaje:</label>
                                        <textarea
                                            type="mensaje" id="mensaje"
                                            value={mensaje}
                                            onChange={(e) => {
                                                if (e.target.value.length <= caracteresMax) {
                                                    setMensaje(e.target.value);
                                                    setCaracteres(e.target.value.length);
                                                }
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.keyCode === 13 && e.shiftKey === false) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            maxLength={caracteresMax}
                                            required
                                            className="mensaje-textarea"
                                        ></textarea>
                                        <p className="caracteresp">{caracteres} de {caracteresMax} caracteres máximos.</p>


                                        <button type="submit" className="submitb">Enviar</button>
                                    </form>
                                ) : (
                                    <div>
                                        <h3>¡Gracias por contactarnos!</h3>
                                    </div>
                                )}
                            </div>

                        </section>

                    </div>





                    <div className='AYM__CONTRATISTAS__DIV__slide'>
                        <h2 id="TrabajosRecientes" className="formularioh2">Trabajos recientes</h2>
                        <section className='contenedor__slider'>

                            <div className='slider'>
                                <div className='slide slide1 fade'>

                                    <div className='slide-content'>
                                    </div>
                                </div>
                                <div className='slide slide2 fade'>
                                </div>
                                <div className='slide slide3 fade'>
                                </div>
                                <div className='slide slide4 fade'>
                                </div>
                                <div className='slide slide5 fade'>
                                </div>

                            </div>

                            <div className='slider__arrow__contenedor'>
                                <button className='slider-arrow slider-arrow-left prevBtn'>
                                    <img src={iconHome[0]} alt='Flecha Izquierda' title='Retroceder' />

                                </button>


                                <button className='slider-arrow slider-arrow-right nextBtn'>
                                    <img src={iconHome[1]} alt='Flecha Derecha' title='Continuar' />
                                </button>

                            </div>

                        </section>


                    </div>






















                </div>

            </section>



        </section>

    );
}

export default Home;