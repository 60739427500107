import React from 'react';
import Home from './componentes/home';
import Footer from './componentes/footer';
import Whatsapp from './componentes/WhatsappAYM';





import './componentes/goldmanh.css';
import './componentes/home.css';
import './componentes/footer.css';
import './componentes/WhatsappAYM.css';
import './assets/normalize.css';
import './App.css';



function App() {
  return (
    <div className="App">


      <Home />

      <Footer />

      <Whatsapp />
    </div>
  );
}

export default App;
