import React from 'react';
import WSP from '../img/AYM__WSP.svg';

const Whatsapp = () => {
    const phoneNumber = '+51958071918';

    const handleClick = (e) => {
        e.preventDefault();
        window.open(`https://wa.me/${phoneNumber}`, '_blank');
    };

    return (
        <div>
            <a className="btn-WSP" href={`https://wa.me/${phoneNumber}`} onClick={handleClick}>
                <img src={WSP} alt="Contactar por WhatsApp" />
            </a>
        </div>
    );
};

export default Whatsapp;
