import AYM__00__MONITOR__PC from './AYM__00__MONITOR__PC.svg';
import AYM__01__MONITOR__VISION from './AYM__01__MONITOR__VISION.svg';
import AYM__02__MONITOR__SEO from './AYM__02__MONITOR__SEO.svg';




const ImgAYM = [
    AYM__00__MONITOR__PC,
    AYM__01__MONITOR__VISION,
    AYM__02__MONITOR__SEO,

];

export default ImgAYM;